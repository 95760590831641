@import url("https://fonts.googleapis.com/css?family=Open+Sans");
/* stylelint-disable no-descending-specificity, at-rule-no-unknown */
/**
 *  Property of OSF Global Services, Inc., (with its brand OSF Commerce). OSF remains the sole owner of all right, title and interest in the software.
 *  Do not copy, sell, reverse engineer or otherwise attempt to derive or obtain information about the functioning, manufacture or operation therein.
 */
/* the values used in this file assume a root font size equivaled to 10 pixels
* the scalesize function scales the values by the actual root font size
* for Sitegenesis and SFRA root font size is equivale to 16 pixels
*/
.success-message {
  transform: translate(-50%, -50%);
  position: fixed;
  top: 15%;
  left: 50%;
  z-index: 999; }

.success-alert {
  animation: fade 5s linear forwards;
  box-shadow: 1px 1px 5px grey;
  padding: 1em; }

@keyframes fade {
  0% {
    opacity: 0; }
  10% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0; } }
  .success-alert.show {
    display: block; }

.full-width .primary-content {
  border: 0 none;
  width: 100%; }

ul.options_link {
  list-style-type: none;
  padding-left: 0; }
  ul.options_link li {
    display: inline; }

.primary-content {
  float: right;
  margin-left: -0.0625rem;
  padding: 0 0 1.25rem;
  position: relative;
  width: 75%; }

.primary-focus .primary-content {
  border: 0 none;
  float: left;
  margin: 0;
  width: 75%; }

.page-content .primary-content {
  padding-left: 1.8125rem; }

#secondary {
  width: 13.125rem; }

#login {
  margin-top: 1.5em;
  margin-bottom: 1.5em; }
  #login .ui-dialog-content {
    padding: 0;
    width: 90% !important;
    margin: 0 auto; }

table.orders {
  border: 0.125rem solid white;
  border-collapse: separate;
  border-spacing: 0.125rem;
  margin-left: -0.125rem;
  width: 97%; }
  table.orders th {
    background-color: #ccc;
    padding: 1em; }
  table.orders tbody tr:nth-child(odd) {
    background-color: #f0f0f0; }

table#subscriptions {
  border: 0.125rem solid white;
  border-collapse: separate;
  border-spacing: 0.125rem;
  margin-left: -0.125rem;
  width: 97%; }
  table#subscriptions th {
    background-color: #ccc;
    padding: 1em; }
  table#subscriptions tbody tr:nth-child(odd) {
    background-color: #f0f0f0; }

.ui-dialog .ui-dialog-content {
  padding: 0;
  width: 90% !important;
  margin: 0 auto; }

#orderview {
  z-index: 99;
  padding: 2em 0 4em;
  font-size: 0.875rem;
  font-family: Arial, sans-serif; }
  #orderview .header {
    border-bottom: 0.0625rem solid black; }
  #orderview .order-section {
    border-bottom: 0.0625rem solid black;
    padding: 1em 0; }
    #orderview .order-section p.title {
      margin-left: 0;
      width: 25%;
      width: 100%; }
    #orderview .order-section .submit {
      position: relative;
      bottom: 2.85em;
      left: 90%; }
    #orderview .order-section table.product {
      margin-top: 1em; }
    #orderview .order-section p.title-little {
      width: 25%; }
    #orderview .order-section .product {
      border-top: 0.0625rem solid black; }
      #orderview .order-section .product thead tr th {
        border-bottom: 0.0625rem solid #c5c5c5;
        padding: 0.625rem 0; }
      #orderview .order-section .product .no-padding-left {
        padding-left: 0; }
      #orderview .order-section .product .quantity {
        width: 25%; }
  #orderview .payment-section {
    border-bottom: 0.0625rem solid black; }
    #orderview .payment-section p {
      font-weight: 900; }
    #orderview .payment-section p.tax {
      font-weight: normal;
      display: block; }
  #orderview .last {
    border: none; }
  #orderview .title {
    font-weight: 900; }
  #orderview .content-section {
    display: inline-block;
    width: 100%; }
    #orderview .content-section table {
      margin-left: -1em;
      margin-bottom: 0.5em;
      width: 100%;
      table-layout: fixed; }
      #orderview .content-section table tr td:first-child {
        font-weight: 900;
        padding: 0.4em 0;
        padding-left: 1em; }
      #orderview .content-section table td {
        max-width: 10%;
        vertical-align: middle;
        white-space: nowrap; }
        #orderview .content-section table td span.name {
          font-weight: normal; }
      #orderview .content-section table td.phone-number {
        width: 7em; }
  #orderview .product td {
    vertical-align: top;
    padding: 1em;
    width: 25%; }
  #orderview .product input {
    display: block;
    width: 100%;
    padding: 0.3125rem; }
  #orderview .product select {
    display: block;
    width: 100%;
    padding: 0.3125rem; }
  #orderview #actions {
    display: inline-block;
    position: relative;
    top: 0.75em;
    width: 100%; }
    #orderview #actions span {
      margin: 0.2em 0; }
  #orderview + .ui-dialog-buttonpane .ui-dialog-buttonset {
    margin: -2em 1em 3em 0; }

#subscriptionview {
  z-index: 99;
  padding: 2em 0 4em;
  font-size: 0.875rem;
  font-family: Arial, sans-serif; }
  #subscriptionview .header {
    border-bottom: 0.0625rem solid black; }
  #subscriptionview .order-section {
    border-bottom: 0.0625rem solid black;
    padding: 1em 0; }
    #subscriptionview .order-section p.title {
      margin-left: 0;
      width: 25%;
      width: 100%; }
    #subscriptionview .order-section .submit {
      position: relative;
      bottom: 2.85em;
      left: 90%; }
    #subscriptionview .order-section table.product {
      margin-top: 1em; }
    #subscriptionview .order-section p.title-little {
      width: 25%; }
    #subscriptionview .order-section .product {
      border-top: 0.0625rem solid black; }
      #subscriptionview .order-section .product thead tr th {
        border-bottom: 0.0625rem solid #c5c5c5;
        padding: 0.625rem 0; }
      #subscriptionview .order-section .product .no-padding-left {
        padding-left: 0; }
      #subscriptionview .order-section .product .quantity {
        width: 25%; }
  #subscriptionview .payment-section {
    border-bottom: 0.0625rem solid black; }
    #subscriptionview .payment-section p {
      font-weight: 900; }
    #subscriptionview .payment-section p.tax {
      font-weight: normal;
      display: block; }
  #subscriptionview .last {
    border: none; }
  #subscriptionview .title {
    font-weight: 900; }
  #subscriptionview .content-section {
    display: inline-block;
    width: 100%; }
    #subscriptionview .content-section table {
      margin-left: -1em;
      margin-bottom: 0.5em;
      width: 100%;
      table-layout: fixed; }
      #subscriptionview .content-section table tr td:first-child {
        font-weight: 900;
        padding: 0.4em 0;
        padding-left: 1em; }
      #subscriptionview .content-section table td {
        max-width: 10%;
        vertical-align: middle;
        white-space: nowrap; }
        #subscriptionview .content-section table td span.name {
          font-weight: normal; }
      #subscriptionview .content-section table td.phone-number {
        width: 7em; }
  #subscriptionview .product td {
    vertical-align: top;
    padding: 1em;
    width: 25%; }
  #subscriptionview .product input {
    display: block;
    width: 100%;
    padding: 0.3125rem; }
  #subscriptionview .product select {
    display: block;
    width: 100%;
    padding: 0.3125rem; }
  #subscriptionview #actions {
    display: inline-block;
    position: relative;
    top: 0.75em;
    width: 100%; }
    #subscriptionview #actions span {
      margin: 0.2em 0;
      float: left; }
  #subscriptionview + .ui-dialog-buttonpane .ui-dialog-buttonset {
    margin: -2em 1em 3em 0; }

#addresschange {
  width: 31.25rem;
  margin: 0;
  overflow: hidden;
  padding: 1em 1.5em; }
  #addresschange .form-row {
    margin: 0; }
  #addresschange #editprofile {
    margin: 1em auto; }
  #addresschange #editAddressForm {
    margin: 0; }
    #addresschange #editAddressForm #dialogApplyBtn {
      position: absolute;
      right: 24%;
      display: none; }
    #addresschange #editAddressForm .select label {
      padding: 0; }
  #addresschange .keynote {
    margin-bottom: 1em; }
  #addresschange + .ui-dialog-buttonpane .ui-dialog-buttonset {
    margin-top: -2em;
    margin-right: 1em;
    margin-bottom: 1em; }

#cancel-all-dialog h3 {
  width: 95%;
  margin: 2em auto 0; }

.select-group {
  float: left; }

.group-change-refill {
  display: inline-block; }
  .group-change-refill .title-little {
    width: auto !important;
    white-space: nowrap; }
  .group-change-refill .select-group {
    float: none !important; }

.update-change-refill {
  display: inline-block;
  margin-right: 0.625rem; }
  .update-change-refill .title-little {
    width: auto !important;
    white-space: nowrap; }
  .update-change-refill .select-group {
    float: none !important; }

.payment-section__card {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.625rem 0;
  border-top: 0;
  border-bottom: 0.0625rem solid #c5c5c5; }

.payment-section__order {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.625rem 0;
  border-top: 0;
  border-bottom: 0.0625rem solid #c5c5c5; }

.payment-section__card--item {
  display: inline-block;
  width: 50%; }
  .payment-section__card--item .title-order {
    font-weight: 900; }
  .payment-section__card--item > * {
    display: inline; }

.payment-section__order--item {
  display: inline-block;
  width: 50%; }
  .payment-section__order--item .title-order {
    font-weight: 900; }
  .payment-section__order--item > * {
    display: inline; }

.payment-section__card--item-full {
  display: inline-block;
  width: 100%;
  text-align: center; }
  .payment-section__card--item-full > * {
    display: inline;
    margin: 0; }

.payment-section__order--item-full {
  display: inline-block;
  width: 100%;
  text-align: center; }
  .payment-section__order--item-full > * {
    display: inline;
    margin: 0; }

.payment-section__card--item-last {
  text-align: right; }

.payment-section__order--item-last {
  text-align: right; }

.update-card {
  display: block;
  margin: 1em 0; }

#my_subscriptions {
  font-family: "Open Sans", sans-serif; }
  #my_subscriptions .subscriptionSection {
    margin-bottom: 3.125rem; }
    #my_subscriptions .subscriptionSection .product-out-of-stock {
      font-weight: bold;
      color: red;
      display: block; }
  #my_subscriptions .subscriptionHeader {
    font-size: 1.75em;
    font-weight: 700;
    margin-bottom: 1.875rem; }
  #my_subscriptions .subscriptionSection-img {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: center;
    align-items: center; }
    #my_subscriptions .subscriptionSection-img img {
      width: 5.625rem;
      height: auto;
      margin: 0.9375rem 0.9375rem 0.9375rem 0; }
    #my_subscriptions .subscriptionSection-img .quantity-name {
      font-weight: 400;
      margin: 0.625rem;
      font-size: 1.125rem; }
      #my_subscriptions .subscriptionSection-img .quantity-name span {
        font-weight: 600; }
    #my_subscriptions .subscriptionSection-img .description-label {
      margin-left: 0.625rem; }
  #my_subscriptions .description-label {
    font-size: 1.3em;
    font-weight: 400; }
  #my_subscriptions .description-value {
    font-size: 1.3em;
    font-weight: 400;
    display: inline-block; }
    #my_subscriptions .description-value > span {
      display: inline-block;
      font-weight: 700;
      line-height: 1em;
      padding-right: 0.625rem;
      border-right: 0.0625rem solid #333; }
      #my_subscriptions .description-value > span:last-child {
        border: none; }
  #my_subscriptions .subscription-buttons {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  #my_subscriptions .show-hide-orders {
    cursor: pointer;
    font-size: 1.5em;
    border-bottom: 0.0625rem solid #9e9e9e;
    padding-bottom: 0.625rem;
    width: calc(100% - scalesize(10rem));
    margin-right: 6.25rem; }
    #my_subscriptions .show-hide-orders:hover {
      text-shadow: 0.25rem 0.25rem 0.375rem #cccaca; }
  #my_subscriptions .subscriptionOrders {
    display: none; }
  #my_subscriptions .sorlink.reactivatesubscription {
    background-color: #2698e8;
    color: #fff;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    height: 2.5rem;
    margin: 0.625rem;
    font-size: 1.3em;
    width: 7.5rem; }
    #my_subscriptions .sorlink.reactivatesubscription img {
      width: 0.75rem;
      height: auto;
      margin-right: 0.1875rem; }
  #my_subscriptions .sorlink.view {
    background-color: #7fbc00;
    color: #fff;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    width: 5.9375rem;
    height: 2.5rem;
    margin: 0.625rem;
    font-size: 1.3em; }
    #my_subscriptions .sorlink.view img {
      width: 0.9375rem;
      height: auto;
      margin-right: 0.1875rem; }

.subscriptionOrders .orders {
  list-style-type: none;
  padding: 0.3125rem;
  margin: 0.625rem 6.25rem 0.625rem 0; }
  .subscriptionOrders .orders li {
    border-bottom: 0.0625rem solid #e0dede;
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: justify;
    justify-content: space-between; }
    .subscriptionOrders .orders li:last-child {
      border: none; }

.subscriptionOrders .orders-date {
  font-weight: 700;
  font-size: 1.3em; }

.subscriptionOrders .orders-buttons {
  font-weight: 700;
  display: -ms-flexbox;
  display: flex; }
  .subscriptionOrders .orders-buttons .order.skip.sorlink {
    all: unset;
    color: #e27252;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    width: 4.375rem;
    height: 1.25rem;
    font-size: 1.3em;
    margin: 0.625rem 0;
    cursor: pointer; }
    .subscriptionOrders .orders-buttons .order.skip.sorlink img {
      width: 0.75rem;
      height: auto;
      margin-right: 0.1875rem; }
  .subscriptionOrders .orders-buttons #subscriptionOrders__view-button.order.view.sorlink {
    color: #7fbc00;
    background-color: transparent;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    width: 3.125rem;
    height: 1.25rem;
    font-size: 1.3em; }
    .subscriptionOrders .orders-buttons #subscriptionOrders__view-button.order.view.sorlink img {
      width: 0.8125rem;
      height: auto;
      margin-right: 0.1875rem; }
  .subscriptionOrders .orders-buttons .order.reactivate.sorlink {
    color: #2698e8;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 1.3em;
    margin: 0.625rem 0;
    width: 5.625rem;
    height: 1.25rem; }
    .subscriptionOrders .orders-buttons .order.reactivate.sorlink img {
      width: 0.75rem;
      height: auto;
      margin-right: 0.1875rem; }

span.sorlink {
  cursor: pointer;
  color: #84bd00;
  outline: medium none;
  overflow: hidden;
  text-decoration: none;
  font-size: 0.875rem; }

.hide {
  display: none; }

#pdpMain {
  font-family: "Open Sans", sans-serif; }

.sor-options-description {
  all: initial;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  margin-top: 0;
  color: #333;
  display: block; }

.smart-order-refill-options .or-cancel-edit-text {
  all: initial;
  position: relative;
  max-width: 100%;
  font-size: 0.9375rem;
  margin: 0.625rem 0.625rem 0.9375rem 0.625rem;
  font-family: "Open Sans", sans-serif;
  color: #333;
  display: block; }

.smart-order-refill-options .full_row {
  position: relative;
  padding: 0 0.375rem;
  margin: 0.9375rem 0; }
  .smart-order-refill-options .full_row input[type="radio"] {
    display: none; }
  .smart-order-refill-options .full_row label {
    all: initial;
    font-family: "Open Sans", sans-serif;
    color: #666;
    font-size: 1rem;
    font-weight: normal;
    display: inline-block;
    margin: 0;
    max-width: 85%;
    position: relative;
    vertical-align: middle; }
    .smart-order-refill-options .full_row label::before {
      all: initial;
      content: " ";
      display: inline-block;
      position: relative;
      top: 0.3125rem;
      margin: 0 0.3125rem 0 0;
      width: 1rem;
      height: 1rem;
      border-radius: 0.6875rem;
      border: 0.125rem solid #84bd00;
      background-color: transparent; }
  .smart-order-refill-options .full_row input[type=radio]:checked + label::after {
    border-radius: 0.6875rem;
    width: 0.75rem;
    height: 0.75rem;
    position: absolute;
    bottom: 0.25rem;
    left: 0.25rem;
    content: " ";
    display: block;
    background: #84bd00; }

.smart-order-refill-options .smart-order-refill-period {
  display: inline-block;
  vertical-align: middle; }

.smart-order-refill-options .sor-price {
  all: initial;
  font-family: "Open Sans", sans-serif;
  font-size: 1.25rem;
  margin: 0;
  max-width: 85%;
  position: relative;
  display: block;
  font-weight: bold;
  margin-left: 0.9375rem; }

.sor-single-option {
  font-size: 1rem;
  font-weight: 600;
  margin: 0; }

.sor-single-option__message {
  font-weight: 400; }

#everyDelivery {
  display: inline-block;
  margin-left: 0.4375rem;
  width: inherit; }

#SorDeliveryMonthInterval {
  display: inline-block;
  margin-left: 0.4375rem;
  width: inherit; }

#SorDeliveryWeekInterval {
  display: inline-block;
  margin-left: 0.4375rem;
  width: inherit; }

.smartOrderRefillOnly h3 {
  all: initial;
  font-family: "Open Sans", sans-serif;
  font-size: 1.25rem;
  margin: 0;
  max-width: 85%;
  position: relative;
  display: block;
  font-weight: bold;
  margin-left: 0.375rem;
  margin-bottom: 0.625rem; }

.refill-info-links {
  font-size: 0.75rem;
  line-height: 1em; }

#modify-smart-order-refill .option {
  position: relative;
  padding: 0 0.375rem;
  margin: 0.9375rem 0; }
  #modify-smart-order-refill .option input[type="radio"] {
    display: none; }
  #modify-smart-order-refill .option label {
    all: initial;
    font-family: "Open Sans", sans-serif;
    color: #666;
    font-size: 0.875rem;
    font-weight: normal;
    display: inline-block;
    margin: 0;
    position: relative;
    vertical-align: middle; }
    #modify-smart-order-refill .option label::before {
      all: initial;
      content: " ";
      display: inline-block;
      position: relative;
      top: 0.3125rem;
      margin: 0 0.3125rem 0 0;
      width: 0.875rem;
      height: 0.875rem;
      border-radius: 0.6875rem;
      border: 0.125rem solid #84bd00;
      background-color: transparent; }
  #modify-smart-order-refill .option label.hide {
    display: none; }
  #modify-smart-order-refill .option input[type=radio]:checked + label::after {
    border-radius: 0.6875rem;
    width: 0.625rem;
    height: 0.625rem;
    position: absolute;
    bottom: 0.1875rem;
    left: 0.25rem;
    content: " ";
    display: block;
    background: #84bd00; }

#modify-smart-order-refill .refill-period {
  display: inline-block;
  vertical-align: middle; }

#modify-smart-order-refill select {
  border: 0.0625rem solid darkgray;
  position: relative;
  margin-left: 0.3125rem;
  height: 1.125rem;
  font-size: 0.6875rem;
  font-family: "Open Sans", sans-serif;
  width: inherit; }

#modifyRefill + #removeRefill::before {
  content: "|";
  padding: 0 0.25rem 0 0.125rem;
  color: initial; }

#message_wrapper {
  all: unset;
  font-family: Arial, sans-serif;
  font-size: 0.75rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  height: 2.25rem;
  background-color: #e27252;
  cursor: default; }
  #message_wrapper a {
    all: unset;
    color: #fff;
    font-size: 1em;
    cursor: pointer; }
    #message_wrapper a strong {
      all: unset;
      color: #fff;
      font-size: 1em;
      font-weight: bold; }
  #message_wrapper p {
    all: unset;
    color: #fff;
    font-size: 1em; }

.sorlogin-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.sorlogin-col {
  -ms-flex: 1;
  flex: 1;
  max-width: 48%; }
  .sorlogin-col h2 {
    all: unset;
    font-family: "Open Sans", sans-serif;
    text-transform: uppercase;
    font-style: italic;
    font-size: 1.3125rem;
    margin: 0.3125rem auto; }

#sorlogin .login-rememberme {
  display: inline-block;
  margin: 0.375rem; }
  #sorlogin .login-rememberme label {
    display: inline-block;
    vertical-align: middle; }
  #sorlogin .login-rememberme .field-wrapper {
    display: inline-block;
    vertical-align: middle; }

#loginFromCartError {
  background-color: #cebdb3;
  border: 0.0625rem solid #900;
  border-radius: 0.5em;
  color: #900;
  margin: 0.0625rem 0;
  padding: 0.625rem;
  font-family: Arial, sans-serif;
  font-size: 0.75rem; }

.sor-form-row {
  margin: 0.5rem 0 1rem; }
  .sor-form-row .hide {
    display: none; }
  .sor-form-row label {
    all: unset;
    display: block;
    font-size: 0.8125rem;
    font-weight: 700;
    margin-bottom: 0.1875rem;
    font-family: Arial, sans-serif; }
    .sor-form-row label span {
      all: unset; }
  .sor-form-row button {
    display: inline-block; }
  .sor-form-row .required-indicator {
    all: unset;
    color: #900;
    font-size: 1rem;
    font-family: inherit; }
  .sor-form-row .field-wrapper {
    all: unset;
    box-sizing: border-box;
    margin-bottom: 0.0625rem;
    width: 100%;
    display: block; }
    .sor-form-row .field-wrapper label.error {
      background-color: #faeded;
      color: #900;
      padding: 0.3125rem;
      margin-top: 0.1875rem; }
  .sor-form-row input[type="text"] {
    all: unset;
    border: 0.0625rem solid #aaa;
    box-sizing: border-box;
    height: 2rem;
    padding: 0.5rem 0.375rem;
    text-indent: 0;
    width: 100%;
    font-family: Arial, sans-serif;
    font-size: 0.75rem; }
  .sor-form-row input[type="number"] {
    all: unset;
    border: 0.0625rem solid #aaa;
    box-sizing: border-box;
    height: 2rem;
    padding: 0.5rem 0.375rem;
    text-indent: 0;
    width: 100%;
    font-family: Arial, sans-serif;
    font-size: 0.75rem; }
  .sor-form-row input[type="password"] {
    all: unset;
    border: 0.0625rem solid #aaa;
    box-sizing: border-box;
    height: 2rem;
    padding: 0.5rem 0.375rem;
    text-indent: 0;
    width: 100%;
    font-family: Arial, sans-serif;
    font-size: 0.75rem; }
  .sor-form-row input[type="date"] {
    all: unset;
    border: 0.0625rem solid #aaa;
    box-sizing: border-box;
    height: 2rem;
    padding: 0.5rem 0.375rem;
    text-indent: 0;
    width: 100%;
    font-family: Arial, sans-serif;
    font-size: 0.75rem; }
  .sor-form-row select {
    all: unset;
    border: 0.0625rem solid #aaa;
    box-sizing: border-box;
    height: 2rem;
    padding: 0.5rem 0.375rem;
    text-indent: 0;
    width: 100%;
    font-family: Arial, sans-serif;
    font-size: 0.75rem; }
  .sor-form-row textarea {
    all: unset;
    border: 0.0625rem solid #aaa;
    box-sizing: border-box;
    height: 2rem;
    padding: 0.5rem 0.375rem;
    text-indent: 0;
    width: 100%;
    font-family: Arial, sans-serif;
    font-size: 0.75rem; }
  .sor-form-row label.updatecard-expiration-label {
    display: block;
    width: 100%; }

.sor-form-row.label-inline label {
  display: inline;
  float: none;
  margin-top: 0;
  text-align: left;
  width: auto; }

.sor-form-row.label-inline input[type="checkbox"] {
  margin: 0 0.3125rem 0 0; }

.sor-form-row.label-inline input[type="radio"] {
  margin: 0 0.3125rem 0 0; }

.sor-form-row.label-inline::after {
  content: " ";
  clear: both; }

.sor-form-row.label-inline .field-wrapper {
  float: left;
  padding-left: 0;
  width: auto; }

.mini-cart-link-checkout.hide-checkout-link {
  visibility: hidden;
  margin: 0; }

.credit_card_save_text {
  margin: 0.625rem auto;
  width: 55%; }
  .credit_card_save_text .credit_card_expiration_warning {
    font-weight: bold;
    color: red;
    margin: 0; }

.tab-content .credit_card_save_text {
  width: auto; }

.payment-method .save_card {
  display: none; }

.refill-dashboard {
  font-size: 0.75rem; }

.form-row.sor-form-row.month {
  margin-right: 0.625rem; }

#editCreditCard .choose-card-wrapper {
  display: flex;
  flex-wrap: wrap; }
  #editCreditCard .choose-card-wrapper .choose-card-item {
    margin: 0.625rem;
    padding: 0.625rem;
    border: 0.0625rem solid #c5c5c5;
    box-sizing: border-box;
    flex: 100%; }
    #editCreditCard .choose-card-wrapper .choose-card-item:hover {
      background: #eee; }
    #editCreditCard .choose-card-wrapper .choose-card-item .choose-card-row {
      display: flex;
      padding: 0.3125rem 0;
      border-top: 0.0625rem solid #c5c5c5; }
      #editCreditCard .choose-card-wrapper .choose-card-item .choose-card-row:first-child {
        border: 0 none; }
      #editCreditCard .choose-card-wrapper .choose-card-item .choose-card-row span {
        flex: 1; }
      #editCreditCard .choose-card-wrapper .choose-card-item .choose-card-row input {
        margin: 0;
        padding: 0;
        vertical-align: text-bottom; }

.hide-mobile {
  display: block; }

.show-mobile {
  display: none; }

@media (min-width: 46.875rem) and (max-width: 64rem) {
  #primary {
    width: 75%;
    padding-left: 0;
    border: 0; }
  #secondary {
    width: 23%; }
  .primary-focus #secondary {
    width: 23%; } }

@media (max-width: 46.875rem) {
  #main {
    padding-top: 0.9375rem; }
  #my_subscriptions {
    overflow-x: scroll; }
    #my_subscriptions .subscriptionSection-img {
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse; }
    #my_subscriptions .subscription-info {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex-align: center;
      align-items: center; }
    #my_subscriptions .show-hide-orders {
      width: 100%;
      margin-right: 0;
      text-align: center; }
      #my_subscriptions .show-hide-orders .down-arrow {
        display: inline-block;
        width: 0.625rem;
        margin: 0.625rem;
        margin-top: 0.3125rem; }
    #my_subscriptions .subscriptionHeader {
      text-align: center; }
  .primary-content {
    width: 100%; }
    .primary-content .form-row:not(.label-inline) .field-wrapper {
      width: 100%; }
    .primary-content .form-row.form-row-button {
      text-align: center; }
  .primary-focus .primary-content {
    width: 100%; }
  #secondary {
    width: 100%; }
  .group-change-refill {
    margin: 0 0 0.625rem 0; }
  .payment-section__card--item {
    width: 100%; }
  .payment-section__order--item {
    width: 100%; }
  #subscriptionview #actions span:last-of-type {
    float: left; }
  #subscriptionview .order-section p.title-little {
    display: inline; }
  .subscriptionOrders .orders {
    margin: 0.625rem 0.625rem 0.625rem 0; } }

@media (max-width: 31.25rem) {
  .orders-buttons {
    -ms-flex-direction: column;
    flex-direction: column; } }

@media (max-width: 28.125rem) {
  .hide-mobile {
    display: none; }
  .show-mobile {
    display: block; } }
